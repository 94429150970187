<template>
  <div class="border">
    <form class="form">
      <div class="card-body fs-8">
        <div class="form-group row">
          <div class="col-12 col-md-4 d-flex">
            <label for="artigo" class="col col-form-label fs-6 fw-bold"
            >Artigo:</label
            >
            <div class="col-9">
              <select
                  class="form-select form-select-sm form-select-solid"
                  id="inputArtigo"
                  :value="productField.art"
                  @change="updateProd($event.target.value)"
              >
                <option
                    v-for="artigo in currInvoice.linhas"
                    :disabled="isSelected(artigo.designacao)"
                    :key="artigo.id"
                    :value="artigo.id"
                    :style="optionclass(artigo.designacao)"
                >
                  {{ artigo.designacao }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-md-4 d-flex">
            <label for="reference" class="col col-form-label fs-6 fw-bold"
            >Referência:</label
            >
            <div class="col-md-9 " style="align-self: center">
              <input
                  v-if="productField.art"
                  type="text"
                  readonly
                  class="form-control-plaintext text-muted text-end text-md-start"
                  :value="productField.ref"
              />
            </div>
          </div>
          <div class="col-12 col-md-4 row justify-content-between justify-content-md-start "
               style="padding-right: 0px;">
            <label for="quantity" class="col col-form-label fs-6 fw-bold"
                   style="max-width:fit-content; margin-right:20px;"
            >Quantidade:</label
            >
            <div class="col-auto " style="padding-right:0px; min-width:60px;">
              <select
                  v-if="productField.art"
                  class="form-select form-select-sm form-select-solid fs-9 fs-md-8 fs-lg-7"
                  id="inputArtigoQtt"
                  :value="productField.qtt"
                  @change="updateProdQtt($event.target.value)"
              >
                <option
                    v-for="(quantity, index) in parseInt(
                    productField.prod.quantidade
                  )"
                    :key="index"
                    :value="quantity"
                >
                  {{ quantity }}
                </option>
              </select>
              <FieldErrorMessage
                  class="mt-1"
                  :errors="productField.errors"
                  fieldName="quantity"
              ></FieldErrorMessage>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <div class="form-group">
            <label class="fs-6 fw-bold" for="text-content">Detalhe</label>
            <textarea
                class="form-control"
                rows="1"
                @change="updateProdObservations($event.target.value)"
                :value="productField.observations"
            ></textarea>
          </div>
        </div>

        <div class="form-group row mt-md-6">
          <div class="text-end">
            <button
                @click.prevent="RemoveProdFormBody"
                class="badge btn-remove-color badge-sm"
                style="border: 0px !important"
            >
              Remover
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>


<script>
import FieldErrorMessage from "@/components/global/FieldErrorMessage.vue";

export default {
  name: "FormBodyProduct",
  props: {
    index: Number,
    productField: Object,
    currInvoice: Object,
    motivations: Array,
    selectedProducts: Array,
  },
  components: {
    FieldErrorMessage,
  },
  watch: {
    "productField.art"(newval, oldval) {
      this.$emit("updateSelectedProducts", newval, oldval)
    },
  },
  computed: {},

  emit: ["RemoveProdFormBody, updateProductField, updateProdQtt, updateSelectedProducts", "updateProdObservations"],

  methods: {

    /*
    * ----------------DATA MANIPULATION----------------
    */

    /*
    * ------------------EMITS------------------
    */

    RemoveProdFormBody() {
      this.$emit("RemoveProdFormBody", this.index, this.productField.art);
    },
    updateProd(value) {
      //this.productField[field] =  value

      this.$emit("updateProductField", this.productField, value);
    },
    updateProdQtt(value) {
      this.$emit("updateProdQtt", this.productField, value);
    },
    updateProdObservations(value) {
      this.$emit("updateProdObservations", this.productField, value);
    },
    /*
    * -------------------UTILS--------------------
    */

    isSelected(prod) {
      if (this.selectedProducts.includes(prod))
        return true;

      return false;
    },

    /*
    * -------------------STYLES-------------------
    */
    optionclass(prod) {
      //if product is selected
      if (this.selectedProducts.includes(prod)) {
        return "background: #c8c8c8 !important; color: white "
      }
    }

  },
};
</script>

<style scoped>
option:active {
  background: red;
}

.btn-remove-color {
  background: #e30b17 !important;
  color: white !important;
}

.btn-remove-color:hover {
  background: #970c13 !important;
  color: white !important;
}

</style>